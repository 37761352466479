@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);

:root {
  --clr-primary-200: #0747a6;
  --clr-primary-400: #fa4f55;

  --clr-neutral-100: #fff;
  --clr-neutral-900: #222c2a;

  --ff-primary: "Roboto", sans-serif;
  --ff-accent: "Lato", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  background: #f2f2f2;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}

h1,
h2,
h3 {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  font-weight: 900;
  line-height: 1;
  margin-bottom: 1rem;
}

h2,
h3,
p {
  margin-bottom: 1em;
}
h1 {
  font-size: 18px;
  line-height: 1.3;
}
a {
  color: #0052cc;
  text-decoration: none;
}

a:focus {
  outline: 2px solid #4c9aff;
}

img {
  display: block;
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.page {
  margin-top: 65px;
}

.split {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.split.reverse {
  flex-direction: column-reverse;
}
.alignStart {
  align-items: flex-start;
}
.justifyStart {
  justify-content: flex-start;
}

.activeColor {
  color: green !important;
}
.HomeCompanyAppsWrapper {
  display: flex;
  flex-wrap: wrap;
}

.container {
  margin: 0 auto;
  width: min(90%, 70.5rem);
}
.container--narrow {
  max-width: 34rem;
  margin: 0 auto;
  padding: 0 10px;
}

.bg-light {
  background-color: #0747a6;
  background-color: var(--clr-primary-200);
}

.bg-primary {
  color: #fff;
  color: var(--clr-neutral-100);
  background-color: #fa4f55;
  background-color: var(--clr-primary-400);
}

.bg-dark {
  color: #fff;
  color: var(--clr-neutral-100);
  background-color: #222c2a;
  background-color: var(--clr-neutral-900);
}

.bg-primary h2,
.bg-dark h2 {
  color: #0747a6;
  color: var(--clr-primary-200);
}

.botton {
  padding: 5px 15px;
  background: #fa4f55;
  background: var(--clr-primary-400);
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 5px;
}

/* section start */
section {
  padding: 15px;
  flex-grow: 1;
}
.section{
  flex-grow: 1;
}
.homeSection {
  padding:0;
}
.section-heading {
  font-size: 30px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 3rem;
  color: #023A77;
}
.section-heading span:first-child {
  color: #1b4962;
  display: block;
}
.section-heading span:last-child {
  color: #fa4f55;
  color: var(--clr-primary-400);
  display: block;
}
/* section start */

/* Sidebar start */

.sideBar {
  position: fixed;
  left: 0;
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    141deg,
    rgba(250, 80, 85, 1) 0%,
    rgba(157, 0, 198, 1) 100%
  );
  padding: 30px;
  justify-content: space-between;
  color: #fff;
}

.sidebarList {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}

.nav_button {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 5px;
  padding: 5px 15px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav_button:hover {
  background: rgb(0 0 0 / 9%);
}

.nav_button.active_menu {
  background: rgb(0 0 0 / 40%);
}

.nav_button_icon {
  display: inline-block;
  margin-right: 10px;
}

.seperator {
  border-bottom: 1px solid #e6e6e6;
  margin: 10px 0;
}

.drawerBotton {
  position: absolute;
  bottom: 50px;
  right: 0;
  background: #fff;
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  color: #a104c2;
  font-weight: bold;
  padding: 3px 10px;
  cursor: pointer;
}

.helpButton {
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.sidebarProfileButton {
  height: 35px;
  width: 35px;
  background: #f94f56;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff;
  position: relative;
}

.sidebarProfileButton:hover .sidebarProfileSettings {
  display: block;
}

.sidebarProfileSettings {
  display: none;
  position: absolute;
  background: #fff;
  left: 35px;
  width: 150px;
  padding: 10px;
  font-size: 14px;
  flex-direction: column;
}

.sidebarProfileSettings a {
  display: block;
}

/* Sidebar end */
/* Login Page  */
.fullPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.fullPageImage {
  flex: 5 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  flex-direction: column;
}

.fullPageform {
  flex: 4 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loginFormWrapper {
  padding: 30px;
  min-width: 300px;
}

.loginLightMessage {
  color: rgb(94, 108, 132);
  text-align: center;
  font-size: 14px;
}

.loginDarkMessage {
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  color: rgb(94, 108, 132);
  margin-bottom: 30px;
}

.loginLogo {
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.loginPageLinks {
  font-size: 14px;
}

.loginHeading {
  margin-bottom: 2rem;
}

.loginBanner {
  max-width: 500px;
  margin-top: 5rem;
}

/* Welcome page  */
.SignupWelcomePage {
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  max-width: 500px;
}

.SignupWelcomePage img {
  max-width: 200px;
  margin: 0 auto;
}

/* Notification list  */
.notifList {
  display: flex;
  margin: 30px 0;
  flex-wrap: wrap;
  flex-direction: column;
}

.notifList > div {
  padding: 5px 10px;
  margin: 5px 0;
  background: #fff;
  border-radius: 8px;
  font-size: 14px;
}

/* List start  */
.List {
  display: flex;
  margin: 15px 0;
  flex-wrap: wrap;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.formList {
  display: flex;
  margin: 15px 0;
  flex-wrap: wrap;
  justify-content: flex-end;
}

/* List end  */
.selectBox {
  margin-bottom: 10px !important;
}

/* Card  start*/
.detailHeading {
  font-size: 12px;
  font-weight: bold;
}

.detailValue {
  font-size: 14px;
}

.notificationCard {
  color: #222c2a;
}

.notificationHeading {
  font-weight: bold;
}

/* Card end */

/* Details pages */
.detailsItem {
  display: flex;
  padding-bottom: 0.5rem;
  font-size: 13px;
}

.detailsKey {
  font-weight: bold;
  text-transform: capitalize;
  min-width: 100px;
}

/* Details pages */

.TableHeader {
  font-weight: bold !important;
}

.features {
  padding: 3em 0;
}

.buttons {
  margin-left: 1em;
}

.hero {
}

.hero-body {
  display: flex;
}

.hero-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.content {
  max-width: 250px;
  min-width: 250px;
  padding: 10px;
  margin: 10px;
  border: 1px solid #f2f2f2;
  background: #f2f2f2;
}

/* Forms */
.formSeperator {
  margin-top: 50px;
}

.form-item-wrapper {
  margin-bottom: 10px;
}

.formContainer {
  padding: 15px;
}

.searchUserBox {
  margin-top: 30px;
  padding: 15px;
  border: 1px solid #ccc;
}

.searchUserFormWrap {
  display: flex;
  justify-content: space-between;
}

.successText {
  color: green;
  font-size: 14px;
}

.failureText {
  color: red;
  font-size: 14px;
}

.formConfirmationMsg {
  margin: 10px 0;
  font-size: 16px;
}

.bold {
  font-weight: bold !important;
}

.verticalSpacer15 {
  width: 15px;
  display: inline-block;
}

.FormInput {
  min-width: 320px !important;
}

.detailsDrawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: calc(100% - 230px);
  padding: 75px 20px;
  background: #fff;
  z-index: 99;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow-y: scroll;
}

.detailsCloseBtn {
  position: absolute !important;
  right: 0;
  top: 0;
}

.DataGridHeading {
  font-weight: bold;
}

.link {
  color: #0645ad !important;
}

.help.is-danger {
  color: red;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.paper {
  padding: 15px;
}

.collapsed {
  width: 50%;
}

/* Config Page */

.configPageWrapper {
  display: flex;
}

/* IBC specific styles */
.UserHeader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  background: #ddd;
  display: flex;
  align-items: center;
  padding: 10px;
  z-index: 9;
}

.businessServicesWrapper {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
}

.UserPageWrapper {
  margin-top: 50px;
  margin-bottom: 50px;
  width: calc(100% - 240px);
  margin-left: auto;
}

.UserFooterItem {
  flex: 1 1;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UserFooterItem img {
  width: 40px;
  margin: 0 auto;
}

/* Ticket app  */
.TicketdetailsDrawer {
  position: fixed;
  top: 50px;
  right: 50px;
  bottom: 50px;
  left: 50px;
  padding: 27px 20px;
  background: #fff;
  display: grid;
  box-shadow: 0px 0px 0px 9999px rgb(0 0 0 / 50%);
  grid-template-columns: 60% 35%;
  grid-gap: 30px;
  grid-gap: 30px;
  gap: 30px;
  z-index: 99;
}

.ticketDetailsWrapper {
  margin-right: 2%;
  border: 1px solid #c7c7c7;
  padding: 15px;
  max-height: 100%;
  overflow: scroll;
  margin-top: 25px;
}

.ticketsDocumentWrapper {
  padding-top: 15px;
}

.ticketsCommentWrapper {
  width: 80%;
  padding-top: 30px;
}

.ticketDetailsCommentItem {
  background: rgb(241, 241, 241);
  padding: 5px 10px;
  margin-bottom: 5px;
  border-left: 3px solid #0747a6;
  border-radius: 20px;
}

.ticketDetailsCommentHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(240, 240, 240);
}

.ticketDetailsCommentUser {
  color: #0052cc;
}

.ticketDetailsCommentMetaWrap {
  font-size: 0.7rem;
}

.ticketAddBtn {
  position: absolute !important;
  top: 84px;
  right: 30px;
}

.ticketDetailsDocumentItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cfcfcf;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 5px;
}

.ticketDetailsSaveBtn {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.ticketDetailsCommentFormWrap {
  padding: 15px 0;
}

.ticketDetailsCommentFormWrap .input {
  margin-bottom: 10px;
}

#TicketTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.scrolable {
  overflow-y: scroll;
  padding-right: 30px;
  height: calc(100% - 30px);
}

.Document-form-item-wrapper {
  padding: 15px 10px;
  background: #efefef;
}
.TicketsPage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 30px;
}
.TicketTablePaper {
  padding: 10px;
}
.ticketTagHeadingWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ticketTagAddButtonWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ticketsDocumentItem {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #dedede;
  padding: 5px;
  text-align: center;
}
.ticketsDocumentItem .pg-viewer-wrapper {
  height: 150px;
  width: 120px;
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
}
.projectListCardName,
.sprintListCardName {
  padding: 5px 0;
}
.ticketsDocumentItemWrapper {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.ticketsDocumentItemName {
  padding-bottom: 10px;
  font-size: 10px;
}
.ticketsDocumentItem .pg-viewer-wrapper .pg-viewer {
  width: 100%;
}
.ticketsDocumentItemBtnWrapper {
  display: flex;
}
.ticketCreateProjectForm {
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
  grid-gap: 6px;
  gap: 6px;
}
.ticketProjectSearch {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.scrolable {
  overflow-y: scroll;
  padding-right: 30px;
  margin-bottom: 50px;
}

.UserOrgConfirmationPageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.UserOrgConfirmationPageWrapper img {
  width: 200px;
}

.redirect_message {
  font-size: 13px;
  margin-top: 15px;
}

.PaymentPopUpWrapper {
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
  padding-top: 30px;
}

.PaymentPopUpIcon {
  padding: 15px;
  background: #f94f55b6;
  width: 100px;
  margin: 0 auto;
  margin-bottom: 15px;
  border-radius: 25px;
}

.PaymentPopUpTitle {
  font-weight: bold;
  font-size: 20px;
  color: rgb(22, 22, 22);
  margin-bottom: 10px;
}

.PaymentPopUpDes {
  font-size: 12px;
  margin-bottom: 30px;
  line-height: 1.3;
}

.PaymentPopUpEyebrow {
  background: #d1e9f8;
  width: -webkit-max-content;
  width: max-content;
  color: #59b1e6;
  padding: 3px 10px;
  border-radius: 25px;
  font-size: 12px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.PaymentPopUpWrapper .price {
  color: #0747a6;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
}

.PaymentPopUpTerms {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PaymentPopUpWrapper button {
  padding: 10px 30px;
  border-radius: 6px;
  background: #0747a6;
  border: unset;
  color: #fff;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
}

/* Notifications */

.NotificationData {
  font-size: 14px;
  margin-bottom: 15px;
}

.NotificationDetail {
  font-size: 16px;
}

.NotificationLabel {
  font-weight: bold;
}

.NotificationStatus {
  margin-bottom: 15px;
  color: #00c853;
}

.TableHeading .MuiTableCell-head {
  font-weight: bold;
}

.PostListFeaturedImage {
  width: 50px;
  height: 50px;
}

/* Support/help page  */
.HelpPageWrapper {
  margin: 30px;
  max-width: 80%;
}

.HelpCategoryWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
  padding: 30px;
  background: #f2f2f2;
}

.HelpCategoryItem {
  min-width: 280px;
  border-radius: 3px;
  box-shadow: 0 0 1px 0 rgb(23 43 77 / 24%);
  padding: 15px;
  background: #fff;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  max-width: 49.5%;
  cursor: pointer;
}

.HelpCategoryItem:hover {
  background-color: #e8f0fe;
}

.HelpCategoryItem img {
  width: 100px;
}

.HelpCategoryItemTitle {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  line-height: 1;
}

.HelpCategoryItemText {
  font-size: 14px;
  color: #333;
  font-weight: 100;
}

/* Faq page  */
.FaqPageWrapper {
  margin: 30px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
  border: 1px solid #d1d1d1;
  min-height: 300px;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.userEditorWrapper {
  border: 0;
  padding: 0;
}
/* Govt schemes */
.govtSchemeCategoryWrapper {
  padding: 10px;
}
.categoryLink:hover {
  color: #0052cc;
  font-weight: bold;
}
/* Course  */

.CourseGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  gap: 30px;
}
.CourseDetailsWrapper {
  background: #eaf5ff;
  padding: 15px;
  margin-top: 15px;
  font-size: 14px;
  margin-bottom: 30px;
}
.CourseModuleWrapper{
  background-color: unset !important;
  box-shadow: unset !important;
  margin-top: 15px;
}

.CourseModuleTumbnail {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
}
.CourseModuleTumbnail.Active {
  background-color: #e8f0fe;
}
.CourseModuleTitle {
  font-weight: bold;
  margin-top: 10px;
}
.CourseModuleTitleTestBtn{
  margin-top: 30px;
}
.CourseWrapperflex {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
}
.courseLineItemAdmin{
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 30px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  align-items: center;
  padding: 10px;
}
.courseLineItemAdmin img{
  width: 200px;
}
.courseLineItemAdminBtnWrap{
  display: flex;
}
.courseAddChaptersForm{
  padding: 20px 0;
}
.visionMissionItem{
  text-align: left;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 15px;
  overflow: hidden;
  min-height: 180px;
  position: relative;
  margin-bottom: 15px;
}
.visionMissionItem h2{
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 15px;
}
.visionMissionItem p{
  font-size: 16px;
}
.visionMissionItem img{
  position: relative;
  right: 0;
  width: 50%;
}
.video_player {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
}
.correctAnswerLabel {
  padding-top: 10px;
  display: inline-block;
  font-size: 11px;
}
.QAoptions{
  -webkit-column-count: 2;
          column-count: 2;
  padding: 0;
  padding-bottom: 30px;
}
.CourseQaAnswer {
  color: red;
}
.CourseQaAnswer.Correct{
  color: green;
}
.QAbox{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 10px;
}
.QApageSection{
  margin: 30px 0;
}
.PageBannerWrapper {
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.PageBanner {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.PageBanner img {
  display: none;
}

.PageBanner h1 {
  font-size: 18px;
  margin-bottom: 5px;
}

.PageBanner p {
  font-size: 12px;
}
.Homepage {
  background: #fff;
}
.homePageBanner {
  display: flex;
  background: #fff;
  padding: 30px 5px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  background-size: cover;
  margin-top: 65px;
  color: rgb(0, 0, 0);
  background-repeat: no-repeat;
}
.homePageBanner h1 {
  font-size: 30px;
  line-height: 1;
  color: #023A77;
}

.homePageBanner p {
  color: #023A77;
  font-size: 14px;
}
.BannerWithGradient {
  background: linear-gradient(91.54deg, #ddd4e7 35.77%, #ce9ca6 72.08%);
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.04);
  border-radius: 20px !important;
}
.HomeCompanyAppsSection {
  padding: 15px 0;
}
.HomeServicesWrapper {
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.HomeServicesCard {
  padding: 15px;
  width: 47%;
  cursor: pointer;
}
.HomeServicesCard h2 {
  margin-bottom: 10px;
}

.HomeServicesCard:hover {
  background-color: #e8f0fe;
}

.HomeServicesCard img {
  max-width: 80px;
  margin-bottom: 10px;
}
.homeSection{
  padding: 3rem 0;
}
.homeEnquireFormSection{
  background: #ebf5ff;
  padding: 0;
}
.homeEnquireForm{
  padding: 2rem 30px;
}
.requestSuccessWrapper img {
  max-width: 150px;
  margin: 0 auto;
}

.requestSuccessWrapper h1 {
  text-align: center;
  font-size: 25px;
}

.requestSuccessWrapper p {
  text-align: center;
}

.requestSuccessDetailsWrapper {
  margin-top: 30px;
  text-align: center;
}

.requestSuccessDetailsWrapper .amount {
  padding: 10px 30px;
  border: 1px solid #c7c7c7;
  display: inline-block;
  border-radius: 6px;
}

.requestSuccessDetailsWrapper .amount .lable {
  font-size: 10px;
  color: #cbcbcb;
}

.requestSuccessDetailsWrapper .amount .figure {
  font-size: 16px;
  font-weight: bold;
}

.HomeCompanyAppsWrapper {
  margin-bottom: 15px;
}

.companyProfileBannerWrapper {
  margin-bottom: 15px;
}

.HomeCompanyAppsItem {
  padding: 10px;
  text-align: center;
  width: 120px;
  cursor: pointer;
}

.HomeCompanyAppsItem:hover {
  background-color: #e8f0fe;
}

.HomeCompanyAppsItem img {
  max-width: 35px;
  margin: 0 auto;
}

.HomeCompanyProfileCard {
  min-width: 280px;
  justify-content: space-between;
  grid-gap: 30px;
  gap: 30px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  color: #fff !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  padding: 20px;
  border-radius: 20px !important;
}

.HomeCompanyProfileCard img {
  max-width: 60px;
  align-self: flex-end;
}

.HomeCompanyProfileCard .CompanyName {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 18px;
}
.HomeCompanyProfileCard .OwnerName {
  font-weight: 400;
  margin-bottom: 30px;
}
.HomeCompanyProfileCard .AddressTitle {
  font-weight: bold;
  font-size: 14px;
}
.HomeCompanyProfileCard .Address {
  font-weight: 400;
}

.companyProfileBanner {
  display: flex;
  align-items: center;
}

.ProfileImageWrap {
  min-width: 175px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}

.companyProfileBanner img {
  max-width: 80px;
  margin: 0 auto;
}

.companyProfileBanner .ProfileBasicsWrap {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 10px;
}

.companyProfileBanner .ProfileBasicsWrap > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px 0;
}

.ProfileBasicsItem span:first-child {
  color: #a7a7a7;
  padding-right: 10px;
}

.ProfileBasicsItem span:last-child {
  font-weight: bold;
  color: #333;
}
.homeServicesCardWrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 30px;
}
.homeServicesCard {
  background: #fff;
  width: 48%;
  padding: 10px;
  box-shadow: 0px 0px 5px 5px rgb(26 26 26 / 6%);
  border-radius: 15px;
  text-align: center;
  min-width: 135px;
}
.homeServicesCardHeading {
  font-size: 12px;
  font-weight: bold;
}
.homeServicesCard img {
  max-width: 100px;
  margin: 0 auto;
}

.ProfileBasicsWrap {
  padding: 15px;
}
.postImage {
  width: 100%;
}

.ToolsCard {
  background: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}
.CategoryHeading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
.categoryLink {
  display: block;
  padding: 10px 0;
  color: #000;
  border-top: 1px solid #d6d6d6;
  font-size: 14px;
}

/* Investor app  */
.invRequestDetailsDrawer {
  position: fixed;
  left: 10%;
  right: 10%;
  top: 15%;
  bottom: 10%;
  background: #fff;
  padding: 2rem 1rem;
  box-shadow: 0px 0px 0px 9999px rgb(0 0 0 / 50%);
  overflow: scroll;
}
.InvReqWorkQueueCardWrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.InvReqWorkQueueCard {
  min-width: 250px;
  max-width: 300px;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.InvReqWorkQueueCardTitle {
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 10px;
}
.InvReqWorkQueueCardDetails > span {
  font-weight: bold;
}
.invReqDetailsLabel {
  font-weight: bold;
}
.invReqDetailsData {
  font-size: 15px;
}


@media (min-width: 40em) {
  h1 {
    font-size: 24px;
  }
  .split {
    flex-direction: row;
  }
  .split.reverse {
    flex-direction: row;
  }
  .split > * {
    flex-basis: 100%;
  }

  .split > * + * {
    margin-left: 2em;
  }
  .page {
    display: flex;
    margin-top: 65px;
  }
  .PageBanner {
    display: flex;
  }
  .PageBannerWrapper {
    margin-bottom: 0 !important;
  }
  .HomeServicesWrapper {
    flex-direction: row;
    justify-content: flex-start;
  }
  .CourseGrid {
    display: grid;
    grid-template-columns: 1fr 300px !important;
    grid-gap: 30px;
    grid-gap: 30px;
    gap: 30px;
    padding-top: 30px;
  }
  .homePageBanner {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    background-position: 100% 100%;
    background-size: cover;
    margin-top: 65px;
    height: 80vh;
    background-repeat: no-repeat;
    color: #222c2a !important;
    color: var(--clr-neutral-900) !important;
  }
  .HomeServicesCard{
    padding: 10px;
    min-width: 138px;
    max-width: 200px;
    cursor: pointer;
    
  }
  .PageBanner img {
    display: block;
    max-width: 220px;
  }

  .visionMissionItem{
    text-align: left;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding: 15px;
    overflow: hidden;
    min-height: 180px;
    position: relative;
  }
  .visionMissionItem h2{
    font-size: 30px;
  }
  .visionMissionItem p{
    font-size: 16px;
  }
  .visionMissionItem img{
    position: absolute;
    right: 0;
  width: 50%;
}
  .video_player {
    width: 100%;
    min-width: 798px;
    max-height: 450px;
  }
}

body {
  background: #fff;
  font-size: 12px;
  font-family: var(--ff-primary);
}
h1 {
  font-size: 1rem;
}

/* Attendance */
.sdp {
  margin: 0 auto;
  width: 100% !important;
  margin-bottom: 10px;
}
.sdp--date-btn__selected {
  background-color: #fc4f4f !important;
}
.timelineDot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  color: #fff;
  line-height: 20px;
}
.timelineDot.apsend {
  background: red;
}
.timelineDot.present {
  background: green;
}
.timelineDot.noupdate {
  background: #aaa;
}

/* Leaves  */
.leaveListPendingCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  text-align: left;
  font-family: var(--ff-primary);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%),
    0px 1px 8px rgb(0 0 0 / 10%);
  margin-top: 10px;
}
.leaveListPendingCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
  width: 100%;
}
.leaveListPendingCardProfile {
  display: flex;
}
.leaveListPendingCardProfile img {
  margin-right: 10px;
  max-width: 45px;
}
.leaveListPendingCardProfileName {
  font-size: 14px;
  font-weight: bold;
}
.leaveListPendingCardProfileDesignation {
  color: #858585;
  font-size: 12px;
}
.leaveListPendingCardLeavetype {
  font-size: 9px;
  background-color: green;
  padding: 3px 10px;
  color: #fff;
  border-radius: 25px;
}
.leaveListPendingCardBody {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.leaveListPendingCardFooter {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.leaveListPendingCardBody > div {
  flex: 1 1;
}
.leaveListPendingCardButtonWrap {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}
.leaveRejectedIndicator {
  border-radius: 25px;
  background: red;
  color: #fff;
  padding: 3px 5px;
  font-size: 9px;
}
.leaveApprovedIndicator {
  border-radius: 25px;
  background: green;
  color: #fff;
  padding: 3px 5px;
  font-size: 9px;
  text-transform: capitalize;
}
.leaveCreateFormWrapper {
  margin-top: 15px;
}
.leaveCreateButtonWrapper {
  display: inline-block;
  margin: 15px 0;
}
.leaveSetupCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  text-align: left;
  font-family: var(--ff-primary);
  margin-top: 10px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%),
    0px 1px 8px rgb(0 0 0 / 10%);
}
.leaveSetupCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.InfoLabel {
  font-size: 10px;
  color: #666;
  text-transform: capitalize;
}
.InfoData {
  font-size: 12px;
  color: #333;
  font-weight: 500;
}

.searchWrapper {
  margin-bottom: 15px;
}

/* attendancelog */

.attendanceLogCard {
  width: 90%;
  margin: 0 auto 21px auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
}

.attendanceLogCardHead {
  display: flex;
  justify-content: space-between;
  padding: 7px 10px;
  background: #f0f0f0;
}
.attendanceLogCardHeadDate {
  font-weight: 600;
  font-size: 12px;
  color: #333333;
}
.attendanceLogCardHeadOff {
  font-weight: 600;
  font-size: 12px;
  color: #666666;
}
.attendanceLogCardContent {
  display: flex;
  justify-content: space-between;
  padding: 10px 9px 15px 9px;
}
.attendanceLogBox:last-child {
  border-right: none;
}
.attendanceLogBox:first-child {
  padding-left: 0px;
}
.attendanceLogBoxTitle {
  font-weight: 400;
  font-size: 12px;
  color: #bdbdbd;
  margin-bottom: 3px;
}
.attendanceLogBoxValue {
  font-weight: 400;
  font-size: 12px;
  color: #969696;
}
.attendanceLogSortWrap .MuiInput-underline:before,
.attendanceLogSortWrap .MuiInput-underline:hover:not(.Mui-disabled):before,
.attendanceLogSortWrap .MuiInput-underline:after {
  border-bottom: none;
}
.attendanceLogSortWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 20px auto;
}
.attendanceLogDateSelect.MuiFormControl-root {
  background: #5db075;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}
.attendanceLogDateSelect .MuiSelect-select.MuiSelect-select {
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  padding: 9px 12px 9px 9px;
  min-width: 70px;
}
.attendanceLogDateSelect .MuiSelect-icon {
  color: #fff;
  right: 3px;
}
.attendanceLogViewSelect.MuiFormControl-root {
  background: #ffffff;
  mix-blend-mode: normal;
  border: 2px solid #5db075;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}
.attendanceLogViewSelect .MuiSelect-select.MuiSelect-select {
  font-weight: 600;
  font-size: 12px;
  color: #5db075;
  padding: 7px 12px 7px 9px;
}
.attendanceLogViewSelect .MuiSelect-icon {
  color: #5db075;
  right: 3px;
}

/* Payroll */
.payRollComponent {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
  padding: 0px 10px;
}
.payRollComponentName {
  font-weight: bold;
  color: #000;
  flex: 1 1;
}
.payRollComponentValue {
  flex: 1 1;
}
.addPayRollComponent {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #e1e1e1;
  grid-gap: 10px;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  text-align: center;
}
.addPayRollComponent > div:last-child {
  margin-left: 15px;
}
.payrollCardInfoGreen {
  color: #5db075;
}
.payrollCardInfoRed {
  color: #fc4f4f;
}

.payrollProfileCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  text-align: left;
  font-family: var(--ff-primary);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%),
    0px 1px 8px rgb(0 0 0 / 10%);
  margin-top: 10px;
}
.payrollProfileCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 15px;
  width: 100%;
}
.payrollProfileCardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payslipDownloadContainer {
  text-align: center;
  margin-bottom: 50px;
}
.payslipDownloadContainer img {
  margin: 30px auto;
  width: 50px;
}
.AppHomePage {
  margin-top: 70px;
}
.AppPage {
  margin-bottom: 120px;
}
.mt {
  margin-top: 10px;
}
.mb {
  margin-bottom: 10px;
}
/* feed card */
.feed_card_wrap {
  background: #ffffff;
  border-radius: 7.41463px;
  padding: 15px 10px;
}
.feed_card_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.feed_card_top_left {
  display: flex;
}
.feed_card_img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.feed_card_profile_details {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
.feed_card_profile_name {
  font-weight: 500;
  font-size: 14px;
  color: #0e0e2c;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.feed_card_company {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 12px;
  color: #fc5252;
}
.option_btn {
  padding: 0;
  border: 0;
  background: transparent;
  color: #4a4a68;
}
.option_btn svg {
  font-size: 30px;
}
.feed_media {
  border-radius: 7px;
  overflow: hidden;
}
.feed_media img {
  width: 100%;
}
.feed_desc {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
  margin-bottom: 0;
}
.feed_title {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
/* company card */
.company_card_wrap {
  padding: 8px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dadada;
}
.company_card_img {
  min-width: 50px;
  border-radius: 50%;
}
.company_card_img img {
  width: 100%;
}
.company_card_details_wrap {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.company_card_details_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.company_card_details_name {
  font-weight: 500;
  font-size: 12px;
  color: #0e0e2c;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}
.company_card_details_tag {
  font-weight: 400;
  font-size: 12px;
  color: #fc5252;
}
.company_card_details_desc {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}
/* post details */
.post_details_media {
  width: 100%;
  border-radius: 8px;
}
.post_details_media img {
  width: 100%;
}
.post_details_profile_wrap {
  display: flex;
  align-items: center;
}
.post_details_profile_img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.post_details_profile_details {
  margin-left: 14px;
  display: flex;
  flex-direction: column;
}
.post_details_profile_name {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;
  color: #0e0e2c;
}
.post_details_profile_company {
  font-weight: 400;
  font-size: 14px;
  color: #fc5252;
}
.post_details_desc {
  font-weight: 400;
  font-size: 14px;
  color: #8c8ca2;
}
/* incubator profile */
.incubator_profile_top_wrap {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}
.incubator_profile_btns_wrap {
  display: flex;
}
.incubator_profile_btn {
  background: #4a4a68;
  box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
    inset 0px -1px 0px rgba(14, 14, 44, 0.4);
  border-radius: 43px;
  padding: 10px 25px;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  margin-right: 10px;
  border: none;
}
.startup_title {
  font-weight: 500;
  font-size: 16px;
  color: #0e0e2c;
  margin-bottom: 10px;
}
.incubator_profile_startup_wrap {
  display: flex;
  flex-wrap: wrap;
}
.incubator_profile_startup_item {
  border-radius: 7px;
  width: 102px;
  height: 94px;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* search */
.search_category_wrap {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.search_category_item {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  padding: 10px 23px;
  background: #fc5252;
  box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
    inset 0px -1px 0px rgba(14, 14, 44, 0.4);
  border-radius: 43px;
  margin-right: 3px;
  margin-bottom: 8px;
}
/* chat screen */
.chat_screen_left_message_wrap {
  margin-bottom: 10px;
  max-width: 70%;
  float: left;
}
.chat_screen_message_status {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-bottom: 8px;
}
.chat_screen_left_message {
  padding: 15px 15px;
  background: #fff;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
  border-radius: 5px 5px 5px 0;
}
.chat_screen_right_message_wrap {
  margin-bottom: 10px;
  max-width: 70%;
  float: right;
}
.chat_screen_right_message {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 15px 15px;
  background: #c4c4c4;
  border-radius: 5px 5px 0 5px;
  margin-bottom: 0;
}
.chat_send_wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
/* company scroll card */
.company_scroll_card_wrapper {
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
}

.company_scroll_card_item {
  width: 50px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}
.company_scroll_card_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.company_scroll_card_img img {
  width: 100%;
  height: 100%;
}
.company_scroll_card_name {
  font-weight: 500;
  font-size: 10px;
  color: #2c2c2c;
  margin-top: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}
.edit_option_btn_drawer .MuiDrawer-paper {
  border-radius: 8px 8px 0px 0px;
}
.edit_option_head {
  background: #9d00c6;
  padding: 12px 17px 12px 17px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
}
.edit_option_tools_wrap {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  padding: 15px 25px;
  margin: 0;
}
.edit_option_tools_item {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8c8ca2;
  margin-bottom: 10px;
}
.edit_option_tools_item_text {
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 4px;
}
.AppLabel {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
}
.AppHomePage {
  margin-top: 70px;
  margin-bottom: 130px;
}

.AppFloatingButtonWrapper {
  padding: 10px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 60px;
  z-index: 99;
  display: flex;
  justify-content: flex-end;
}
.circle_add_btn {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  background: #1bb55c;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 10%), 0px 4px 8px rgb(0 0 0 / 8%),
    0px 1px 12px rgb(0 0 0 / 4%);
}
.form-item-wrapper {
  margin-bottom: 15px;
}
.blue_btn {
  background: #4b4ded;
  /* box-shadow: inset 0px -1px 0.5px rgb(14 14 44 / 40%); */
  border-radius: 25px;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  align-items: center;
  border: none;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
.mt {
  margin-top: 10px;
}
.incubator_profile_startup_item_add {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9d9d9;
  color: #000;
}
.incubator_profile_startup_item_add svg {
  font-size: 40px;
}
.chat_message_count {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fc5252;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  margin-left: auto;
}
.profile_content_details_wrap {
  list-style: none;
  padding-left: 0;
}
.profile_content_details_item {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}
.profile_content_details_label {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
}
.profile_content_details_value {
  font-size: 12px;
  text-transform: capitalize;
  margin-left: 5px;
}
.sc-header {
  background: #262626 !important;
  border-radius: 0 !important;
}
.sc-message {
  max-width: 500px !important;
}

.sc-message--avatar {
  display: none !important;
}
.sc-message--text {
  max-width: unset !important;
  width: calc(100% - 20%) !important;
}
.sc-message--content.sent .sc-message--text {
  background-color: #878787 !important;
}
.sc-message--content.received .sc-message--text {
  background-color: #ffffff !important;
}
.sc-message-list {
  background-color: #ecf1f4 !important;
}
.sc-user-input {
  min-height: unset !important;
}
.sc-user-input--button input[type="file"] {
  display: none !important;
}
.sc-chat-window {
  width: 100vw !important;
  height: 100vh !important;
  position: fixed !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
}
.image_input_wrap {
  background: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px 10px;
}
.image_input_upload_label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  /* margin-bottom: 20px; */
  justify-content: center;
  margin: 0 auto 20px auto;
}
.image_input_upload_label h4 {
  color: #757575;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0px;
}
#image_input_upload {
  display: none;
}
.image_upload_btn_wrap {
  text-align: center;
}
.primary_btn {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  padding: 12px 20px;
  background: #fc5252;
  border: none;
  border-radius: 43px;
}
.secondary_btn {
  display: inline-block;
  background: #31d0aa;
  /* box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
    inset 0px -1px 0px rgba(14, 14, 44, 0.4); */
  border-radius: 43px;
  padding: 12px 24px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  border: none;
  text-transform: capitalize;
  text-align: center;
}
.img_delete_btn {
  box-shadow: 5px 5px 24px rgb(142 139 139 / 9%);
  border: none;
  background: #fc5252;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  margin-left: auto;
}
.w-100 {
  width: 100%;
}

/* chat media button */
button.sc-user-input--file-icon-wrapper {
  display: none;
}

/* --- */

