body {
  background: #fff;
  font-size: 12px;
  font-family: var(--ff-primary);
}
h1 {
  font-size: 1rem;
}

/* Attendance */
.sdp {
  margin: 0 auto;
  width: 100% !important;
  margin-bottom: 10px;
}
.sdp--date-btn__selected {
  background-color: #fc4f4f !important;
}
.timelineDot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  color: #fff;
  line-height: 20px;
}
.timelineDot.apsend {
  background: red;
}
.timelineDot.present {
  background: green;
}
.timelineDot.noupdate {
  background: #aaa;
}

/* Leaves  */
.leaveListPendingCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  text-align: left;
  font-family: var(--ff-primary);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%),
    0px 1px 8px rgb(0 0 0 / 10%);
  margin-top: 10px;
}
.leaveListPendingCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
  width: 100%;
}
.leaveListPendingCardProfile {
  display: flex;
}
.leaveListPendingCardProfile img {
  margin-right: 10px;
  max-width: 45px;
}
.leaveListPendingCardProfileName {
  font-size: 14px;
  font-weight: bold;
}
.leaveListPendingCardProfileDesignation {
  color: #858585;
  font-size: 12px;
}
.leaveListPendingCardLeavetype {
  font-size: 9px;
  background-color: green;
  padding: 3px 10px;
  color: #fff;
  border-radius: 25px;
}
.leaveListPendingCardBody {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.leaveListPendingCardFooter {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.leaveListPendingCardBody > div {
  flex: 1;
}
.leaveListPendingCardButtonWrap {
  display: flex;
  gap: 15px;
}
.leaveRejectedIndicator {
  border-radius: 25px;
  background: red;
  color: #fff;
  padding: 3px 5px;
  font-size: 9px;
}
.leaveApprovedIndicator {
  border-radius: 25px;
  background: green;
  color: #fff;
  padding: 3px 5px;
  font-size: 9px;
  text-transform: capitalize;
}
.leaveCreateFormWrapper {
  margin-top: 15px;
}
.leaveCreateButtonWrapper {
  display: inline-block;
  margin: 15px 0;
}
.leaveSetupCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  text-align: left;
  font-family: var(--ff-primary);
  margin-top: 10px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%),
    0px 1px 8px rgb(0 0 0 / 10%);
}
.leaveSetupCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.InfoLabel {
  font-size: 10px;
  color: #666;
  text-transform: capitalize;
}
.InfoData {
  font-size: 12px;
  color: #333;
  font-weight: 500;
}

.searchWrapper {
  margin-bottom: 15px;
}

/* attendancelog */

.attendanceLogCard {
  width: 90%;
  margin: 0 auto 21px auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
}

.attendanceLogCardHead {
  display: flex;
  justify-content: space-between;
  padding: 7px 10px;
  background: #f0f0f0;
}
.attendanceLogCardHeadDate {
  font-weight: 600;
  font-size: 12px;
  color: #333333;
}
.attendanceLogCardHeadOff {
  font-weight: 600;
  font-size: 12px;
  color: #666666;
}
.attendanceLogCardContent {
  display: flex;
  justify-content: space-between;
  padding: 10px 9px 15px 9px;
}
.attendanceLogBox:last-child {
  border-right: none;
}
.attendanceLogBox:first-child {
  padding-left: 0px;
}
.attendanceLogBoxTitle {
  font-weight: 400;
  font-size: 12px;
  color: #bdbdbd;
  margin-bottom: 3px;
}
.attendanceLogBoxValue {
  font-weight: 400;
  font-size: 12px;
  color: #969696;
}
.attendanceLogSortWrap .MuiInput-underline:before,
.attendanceLogSortWrap .MuiInput-underline:hover:not(.Mui-disabled):before,
.attendanceLogSortWrap .MuiInput-underline:after {
  border-bottom: none;
}
.attendanceLogSortWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 20px auto;
}
.attendanceLogDateSelect.MuiFormControl-root {
  background: #5db075;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}
.attendanceLogDateSelect .MuiSelect-select.MuiSelect-select {
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  padding: 9px 12px 9px 9px;
  min-width: 70px;
}
.attendanceLogDateSelect .MuiSelect-icon {
  color: #fff;
  right: 3px;
}
.attendanceLogViewSelect.MuiFormControl-root {
  background: #ffffff;
  mix-blend-mode: normal;
  border: 2px solid #5db075;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}
.attendanceLogViewSelect .MuiSelect-select.MuiSelect-select {
  font-weight: 600;
  font-size: 12px;
  color: #5db075;
  padding: 7px 12px 7px 9px;
}
.attendanceLogViewSelect .MuiSelect-icon {
  color: #5db075;
  right: 3px;
}

/* Payroll */
.payRollComponent {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
  padding: 0px 10px;
}
.payRollComponentName {
  font-weight: bold;
  color: #000;
  flex: 1;
}
.payRollComponentValue {
  flex: 1;
}
.addPayRollComponent {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #e1e1e1;
  grid-gap: 10px;
  gap: 5px;
  align-items: center;
  text-align: center;
}
.addPayRollComponent > div:last-child {
  margin-left: 15px;
}
.payrollCardInfoGreen {
  color: #5db075;
}
.payrollCardInfoRed {
  color: #fc4f4f;
}

.payrollProfileCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  text-align: left;
  font-family: var(--ff-primary);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%),
    0px 1px 8px rgb(0 0 0 / 10%);
  margin-top: 10px;
}
.payrollProfileCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 15px;
  width: 100%;
}
.payrollProfileCardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payslipDownloadContainer {
  text-align: center;
  margin-bottom: 50px;
}
.payslipDownloadContainer img {
  margin: 30px auto;
  width: 50px;
}
.AppHomePage {
  margin-top: 70px;
}
.AppPage {
  margin-bottom: 120px;
}
.mt {
  margin-top: 10px;
}
.mb {
  margin-bottom: 10px;
}
/* feed card */
.feed_card_wrap {
  background: #ffffff;
  border-radius: 7.41463px;
  padding: 15px 10px;
}
.feed_card_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.feed_card_top_left {
  display: flex;
}
.feed_card_img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.feed_card_profile_details {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
.feed_card_profile_name {
  font-weight: 500;
  font-size: 14px;
  color: #0e0e2c;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.feed_card_company {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 12px;
  color: #fc5252;
}
.option_btn {
  padding: 0;
  border: 0;
  background: transparent;
  color: #4a4a68;
}
.option_btn svg {
  font-size: 30px;
}
.feed_media {
  border-radius: 7px;
  overflow: hidden;
}
.feed_media img {
  width: 100%;
}
.feed_desc {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
  margin-bottom: 0;
}
.feed_title {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
/* company card */
.company_card_wrap {
  padding: 8px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dadada;
}
.company_card_img {
  min-width: 50px;
  border-radius: 50%;
}
.company_card_img img {
  width: 100%;
}
.company_card_details_wrap {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.company_card_details_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.company_card_details_name {
  font-weight: 500;
  font-size: 12px;
  color: #0e0e2c;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}
.company_card_details_tag {
  font-weight: 400;
  font-size: 12px;
  color: #fc5252;
}
.company_card_details_desc {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}
/* post details */
.post_details_media {
  width: 100%;
  border-radius: 8px;
}
.post_details_media img {
  width: 100%;
}
.post_details_profile_wrap {
  display: flex;
  align-items: center;
}
.post_details_profile_img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.post_details_profile_details {
  margin-left: 14px;
  display: flex;
  flex-direction: column;
}
.post_details_profile_name {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;
  color: #0e0e2c;
}
.post_details_profile_company {
  font-weight: 400;
  font-size: 14px;
  color: #fc5252;
}
.post_details_desc {
  font-weight: 400;
  font-size: 14px;
  color: #8c8ca2;
}
/* incubator profile */
.incubator_profile_top_wrap {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}
.incubator_profile_btns_wrap {
  display: flex;
}
.incubator_profile_btn {
  background: #4a4a68;
  box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
    inset 0px -1px 0px rgba(14, 14, 44, 0.4);
  border-radius: 43px;
  padding: 10px 25px;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  margin-right: 10px;
  border: none;
}
.startup_title {
  font-weight: 500;
  font-size: 16px;
  color: #0e0e2c;
  margin-bottom: 10px;
}
.incubator_profile_startup_wrap {
  display: flex;
  flex-wrap: wrap;
}
.incubator_profile_startup_item {
  border-radius: 7px;
  width: 102px;
  height: 94px;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* search */
.search_category_wrap {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.search_category_item {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  padding: 10px 23px;
  background: #fc5252;
  box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
    inset 0px -1px 0px rgba(14, 14, 44, 0.4);
  border-radius: 43px;
  margin-right: 3px;
  margin-bottom: 8px;
}
/* chat screen */
.chat_screen_left_message_wrap {
  margin-bottom: 10px;
  max-width: 70%;
  float: left;
}
.chat_screen_message_status {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-bottom: 8px;
}
.chat_screen_left_message {
  padding: 15px 15px;
  background: #fff;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
  border-radius: 5px 5px 5px 0;
}
.chat_screen_right_message_wrap {
  margin-bottom: 10px;
  max-width: 70%;
  float: right;
}
.chat_screen_right_message {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 15px 15px;
  background: #c4c4c4;
  border-radius: 5px 5px 0 5px;
  margin-bottom: 0;
}
.chat_send_wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
/* company scroll card */
.company_scroll_card_wrapper {
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
}

.company_scroll_card_item {
  width: 50px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}
.company_scroll_card_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.company_scroll_card_img img {
  width: 100%;
  height: 100%;
}
.company_scroll_card_name {
  font-weight: 500;
  font-size: 10px;
  color: #2c2c2c;
  margin-top: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}
.edit_option_btn_drawer .MuiDrawer-paper {
  border-radius: 8px 8px 0px 0px;
}
.edit_option_head {
  background: #9d00c6;
  padding: 12px 17px 12px 17px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
}
.edit_option_tools_wrap {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  padding: 15px 25px;
  margin: 0;
}
.edit_option_tools_item {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8c8ca2;
  margin-bottom: 10px;
}
.edit_option_tools_item_text {
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 4px;
}
.AppLabel {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
}
.AppHomePage {
  margin-top: 70px;
  margin-bottom: 130px;
}

.AppFloatingButtonWrapper {
  padding: 10px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 60px;
  z-index: 99;
  display: flex;
  justify-content: flex-end;
}
.circle_add_btn {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  background: #1bb55c;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 10%), 0px 4px 8px rgb(0 0 0 / 8%),
    0px 1px 12px rgb(0 0 0 / 4%);
}
.form-item-wrapper {
  margin-bottom: 15px;
}
.blue_btn {
  background: #4b4ded;
  /* box-shadow: inset 0px -1px 0.5px rgb(14 14 44 / 40%); */
  border-radius: 25px;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  align-items: center;
  border: none;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
.mt {
  margin-top: 10px;
}
.incubator_profile_startup_item_add {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9d9d9;
  color: #000;
}
.incubator_profile_startup_item_add svg {
  font-size: 40px;
}
.chat_message_count {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fc5252;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  margin-left: auto;
}
.profile_content_details_wrap {
  list-style: none;
  padding-left: 0;
}
.profile_content_details_item {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}
.profile_content_details_label {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
}
.profile_content_details_value {
  font-size: 12px;
  text-transform: capitalize;
  margin-left: 5px;
}
.sc-header {
  background: #262626 !important;
  border-radius: 0 !important;
}
.sc-message {
  max-width: 500px !important;
}

.sc-message--avatar {
  display: none !important;
}
.sc-message--text {
  max-width: unset !important;
  width: calc(100% - 20%) !important;
}
.sc-message--content.sent .sc-message--text {
  background-color: #878787 !important;
}
.sc-message--content.received .sc-message--text {
  background-color: #ffffff !important;
}
.sc-message-list {
  background-color: #ecf1f4 !important;
}
.sc-user-input {
  min-height: unset !important;
}
.sc-user-input--button input[type="file"] {
  display: none !important;
}
.sc-chat-window {
  width: 100vw !important;
  height: 100vh !important;
  position: fixed !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
}
.image_input_wrap {
  background: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px 10px;
}
.image_input_upload_label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  /* margin-bottom: 20px; */
  justify-content: center;
  margin: 0 auto 20px auto;
}
.image_input_upload_label h4 {
  color: #757575;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0px;
}
#image_input_upload {
  display: none;
}
.image_upload_btn_wrap {
  text-align: center;
}
.primary_btn {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  padding: 12px 20px;
  background: #fc5252;
  border: none;
  border-radius: 43px;
}
.secondary_btn {
  display: inline-block;
  background: #31d0aa;
  /* box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
    inset 0px -1px 0px rgba(14, 14, 44, 0.4); */
  border-radius: 43px;
  padding: 12px 24px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  border: none;
  text-transform: capitalize;
  text-align: center;
}
.img_delete_btn {
  box-shadow: 5px 5px 24px rgb(142 139 139 / 9%);
  border: none;
  background: #fc5252;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  margin-left: auto;
}
.w-100 {
  width: 100%;
}

/* chat media button */
button.sc-user-input--file-icon-wrapper {
  display: none;
}

/* --- */
